<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    v-if="isAuthenticated"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <v-img src="/ws.png"></v-img>
    <br />
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img src="/icone.png" width="50px"> </v-img>
      </v-col>
    </v-row>

    <v-list dense>
      <template v-for="(item, index) in itemsMenu">
        <v-list-item
          link
          exact
          :to="item.path"
          :key="index"
          color="primary"
          v-if="!item.children"
          @click.stop="closeNavigationDrawer()"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          append-icon
          :key="index"
          v-model="item.model"
          :prepend-icon="item.icon ? item.icon : item['icon-alt']"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            exact
            class="ml-3"
            :key="i"
            :to="child.path"
            v-for="(child, i) in item.children"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemNavigations",

  data() {
    return {
      menus: [
        {
          icon: "fas fa-chart-line",
          text: "Relatorios",
          path: "/app/painel",
          role: null,
          group: "all",
        },
        {
          icon: "fas fa-users",
          text: "Usuários",
          path: "/app/usuarios",
          role: "usuarios",
          group: "all",
        },
        {
          icon: "fas fa-people-arrows",
          text: "Clientes",
          path: "/app/clientes",
          role: "none",
          group: "responsible",
        },
        {
          icon: "fas fa-folder-open",
          text: "Demandas",
          path: "/app/demandas",
          role: "demandas",
          group: "all",
        },
        {
          icon: "fas fa-plus",
          text: "Solicitações",
          path: "/app/demandas/solicitacoes",
          role: "demandas",
          group: "all",
        },
        {
          icon: "fas fa-folder",
          text: "Processos",
          path: "/app/processos",
          role: null,
          group: "responsible",
          children: [
            {
              icon: "fas fa-eye",
              text: "Ver Processos",
              path: "/app/processos",
              role: null,
              group: "responsible",
            },
            {
              icon: "fas fa-plus",
              text: "Novo Processo",
              path: "/app/processos/formulario",
              role: null,
              group: "responsible",
            },
          ],
        },
        // {
        //   icon: "fas fa-file-export",
        //   text: "Fluxo de Arquivos",
        //   path: "/app/fluxos",
        //   role: "demandas",
        //   group: "responsible",
        // },
        {
          icon: "fas fa-calendar-alt",
          text: "Agenda",
          path: "/app/agenda",
          role: "agenda",
          group: "responsible",
        },
        {
          icon: "fas fa-comments",
          text: "Chat",
          path: "/app/new-chat",
          role: "chat",
          group: "all",
        },
        {
          icon: "fas fa-dollar-sign",
          text: "Comercial",
          path: "/app/comercial",
          role: "chat",
          group: "responsible",
        },
        /** {
          icon: "fas fa-user-shield",
          text: "Permissões",
          path: "/app/permissoes",
          role: "",
          group: "responsible",
        }, */
        {
          icon: "fas fa-users-cog",
          text: "Administrar Sistema",
          path: "/app/admin",
          role: "admin",
          group: "responsible",
        },
        {
          icon: "fas fa-user",
          text: "EDUCA WS",
          path: "/app/academy",
          role: null,
          group: "all",
        },
        {
          icon: "fas fa-user",
          text: "Perfil",
          path: "/app/perfil",
          role: null,
          group: "all",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "getIsAuthenticated",
      systemBarAndNavigation: "getSystemBarAndNavigation",
      me: "getMe",
    }),

    itemsMenu: {
      get() {
        return this.menus.filter((element) => {
          if (!this.me) return false;
          if (
            this.me.role.name == "educa_ws" &&
            !["EDUCA WS", "Perfil"].includes(element.text)
          ) {
            return false;
          }

          if (element.group == "all") return true;

          return this.me.role.type == element.group;
        });
      },
    },

    drawer: {
      get() {
        return this.systemBarAndNavigation;
      },
      set(status) {
        this.$store.dispatch("systemBarAndNavigation", status);
      },
    },
  },

  methods: {
    closeNavigationDrawer() {
      this.itemsMenu.forEach((valor) => {
        if (valor["model"]) {
          valor["model"] = false;
        }
      });
    },
  },
};
</script>
