import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import UUID from "vue-uuid";
import "./plugins/vuetify-mask.js";

Vue.use(UUID);
require("firebase/firestore");
require('firebase/auth')

var firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

// var firebaseConfig = {
//   apiKey: "AIzaSyD88zTj131qxJi_xD7mPRFBAUeOUpy5ovY",
//   authDomain: "wsjus-app.firebaseapp.com",
//   projectId: "wsjus-app",
//   storageBucket: "wsjus-app.appspot.com",
//   messagingSenderId: "19933966497",
//   appId: "1:19933966497:web:2eb612d9860959d57580e0"
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();
var storage = firebase.storage();
var auth = firebase.auth();

window.db = db;
window.storage = storage;
window.auth = auth

Vue.config.productionTip = false;

import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.component("ConfirmDeleteModal", () =>
  import("@/components/Confirm/Delete/Index")
);

Vue.component("ModalChangePassword", () =>
  import("@/components/User/ChangePasswordModal/Index")
);

Vue.component("Paginate", () => import("@/components/Paginate/Index"));
Vue.component("LoadingModal", () => import("@/components/Loading/Modal/Index"));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
