import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

import pt from 'vuetify/lib/locale/pt'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#007bff",
        secondary: "#6c757d",
        tertiary: "#eceeef"
      },
    },
  },

  lang: {
    locales: { pt },
    current: 'pt',
  },

  icons: {
    iconfont: "fa",
  },
});
