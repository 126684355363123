<template>
  <div class="text-center">
    <v-snackbar
      v-model="getToast.show"
      :color="getToast.color"
      :right="true"
      :top="true"
      :timeout="3000"
      @input="close($event)"
    >
      {{ getToast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          color="#FFFFFF"
          @click.stop="handleClickButtonClose()"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Toast",

  computed: {
    ...mapGetters({
      getToast: "getToast",
    }),
  },

  methods: {
    async close(status) {
      if (status === false) {
        await this.$store.dispatch("toast", new Object());
      }
    },

    async handleClickButtonClose() {
      await this.$store.dispatch("toast", new Object());
    },
  },
};
</script>