<template>
  <v-app id="app" style="background: rgb(242, 243, 248);">
    <SystemAppBar v-if="me.id" />
    <SystemNavigation v-if="me.id" />

    <v-main>
      <Toast />
      <ModalChangePassword />

      <router-view></router-view>
    </v-main>

    <navigation-notify v-if="me.id" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Toast from "@/components/Toast/Index";
import SystemAppBar from "@/components/Bars/SystemAppBar";
import SystemNavigation from "@/components/Navigations/SystemNavigations";
import NavigationNotify from './components/Notification/NavigationNotify.vue';

export default {
  name: "App",

  components: {
    Toast,
    SystemAppBar,
    SystemNavigation,
    NavigationNotify
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

};
</script>

<style>
.cursor-pointer {
  cursor: pointer !important;
}

.no-text-transform {
  text-transform: none !important;
}

.no-text-select {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
</style>
