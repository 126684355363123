<template>
  <v-navigation-drawer
    width="350px"
    v-model="drawer"
    fixed
    floating
    right
    temporary
  >
    <v-toolbar elevation="4" color="#f5f5f5" flat>
      Notificações
      <v-spacer></v-spacer>
      <v-btn @click="drawer = false" icon>
        <v-icon>
          fas fa-times
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div class="pa-5 mt-10">
      <div v-for="notification in notifications" :key="notification.id">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex">
            <v-avatar height="40px" min-width="40px" width="40px" class="mr-2">
              <v-icon class="info--text"> fas fa-bell </v-icon>
            </v-avatar>
            <div class="d-flex align-center">
              <p class="font-weight-medium body-2 ma-0 ">
                {{ notification.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-8 mb-2 d-flex justify-end">
          <v-badge color="info" dot overlap>
            <v-btn
              outlined
              class="green--text"
              @click="setViewNotify(notification)"
            >
              Ok
            </v-btn>
          </v-badge>

          <v-spacer></v-spacer>
          <span class="text--disabled caption">
            {{ notification.created_at | formatDate }}
          </span>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import instanceAxios from "../../plugins/axios";
export default {
  data() {
    return {
      group: true,
      drawer: false,
      notifications: [],
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("HH:mm DD/MM/YYYY");
    },
  },

  mounted() {
    this.getNotifys();
    this.$root.$on("openNotifications", () => (this.drawer = true));
    this.$root.$on("updateNotify", () => this.getNotifys());
  },

  methods: {
    async getNotifys() {
      const { data } = await instanceAxios.get("notifications");

      this.notifications = data;
    },
    setViewNotify(notify) {
      instanceAxios.put(`notifications/${notify.id}`);

      this.getNotifys()

      this.$root.$emit("updateNotify");
    },
  },
};
</script>

<style></style>
