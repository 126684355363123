import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import AuthorizationService from "@/services/AuthorizationService";

const checkAuthentication = (to, from, next) => {
  if (store.getters["getIsAuthenticated"]) {
    const meta = to.meta;

    if (meta.role !== undefined) {
      const authorized = AuthorizationService(meta.role);
      if (authorized) {
        return next();
      } else {
        return next(false);
      }
    }

    return next();
  }

  return next("/entrar");
};

const checkRedirectPage = (to, from, next) => {
  if (store.getters["getIsAuthenticated"]) {
    return next("/app/painel");
  }

  return next();
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import("@/components/Layouts/OutsideContextApplication.vue"),
    beforeEnter: checkRedirectPage,
    children: [
      {
        path: "/",
        component: () => import("@/views/Panel/Index.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "/entrar",
        component: () => import("@/views/Login/Index.vue"),
        beforeEnter: checkRedirectPage,
      },
      {
        path: "/solicitar-nova-senha",
        component: () => import("@/views/RequestNewPassword/Index.vue"),
        beforeEnter: checkRedirectPage,
      },
      {
        path: "/criar-nova-senha/:token?",
        component: () => import("@/views/CreateNewPassword/Index.vue"),
        beforeEnter: checkRedirectPage,
      },
      {
        path: "/solicitar-email-verificacao",
        component: () => import("@/views/EmailVerifyResend/Index.vue"),
        beforeEnter: checkRedirectPage,
      },
      {
        path: "/verificar-email/:token?",
        component: () => import("@/views/EmailVerify/Index.vue"),
        beforeEnter: checkRedirectPage,
      },
    ],
  },

  {
    path: "/app",
    component: () => import("@/components/EmptyParentComponent/Index.vue"),
    beforeEnter: checkAuthentication,
    children: [
      {
        path: "painel",
        component: () => import("@/views/Panel/Index.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "agenda",
        component: () => import("@/views/Agenda/Index.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "responsabilidade",
        component: () => import("@/views/Responsabilidade/Index.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "usuarios",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "/",
            component: () => import("@/views/Users/Index.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "formulario",
            component: () => import("@/views/Users/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Users/Show.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Users/Form.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "clientes",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "formulario",
            component: () => import("@/views/Clientes/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Clientes/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Clientes/Show.vue"),
          },
          {
            path: "/",
            component: () => import("@/views/Clientes/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "comercial",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "formulario",
            component: () => import("@/views/Comercial/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Comercial/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Comercial/Show.vue"),
          },
          {
            path: "/",
            component: () => import("@/views/Comercial/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "academy",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "formulario",
            component: () => import("@/views/Videos/Form.vue"),
            beforeEnter: checkAuthentication,
            name: "VideoForm",
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Videos/Form.vue"),
            beforeEnter: checkAuthentication,
            name: "VideoEditForm",
            props: true,
          },
          {
            path: "cursos",
            component: () => import("@/views/Cursos/IndexCursos.vue"),
          },
          {
            path: "cursos/funcionarios",
            props: {
              privateValue: true,
            },
            component: () => import("@/views/Cursos/IndexCursos.vue"),
          },
          {
            path: "cursos/formulario",
            component: () => import("@/views/Cursos/CursoForm.vue"),
            beforeEnter: checkAuthentication,
            name: "CursoForm",
          },
          {
            path: "cursos/formulario/:id",
            component: () => import("@/views/Cursos/CursoForm.vue"),
            beforeEnter: checkAuthentication,
            name: "CursoFormEdit",
            props: true,
          },
          {
            path: "videos/tiraduvidas",
            component: () => import("@/views/Videos/Index.vue"),
            name: "TiraDuvidas",
          },
          {
            path: "videos/:curso",
            component: () => import("@/views/Videos/Index.vue"),
            props: true,
          },
          {
            path: "videos/:private",
            component: () => import("@/views/Videos/Index.vue"),
            name: "VideoPrivate",
            props: true,
          },
          {
            path: ":id",
            component: () => import("@/views/Videos/Show.vue"),
          },
          {
            path: "/",
            component: () => import("@/views/Panel/EducaWS.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "explorar",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "/",
            component: () => import("@/views/Panel/Explorar.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "/cadastro",
            component: () => import("@/views/Panel/Form.vue"),
            beforeEnter: checkAuthentication,
            name: "ExplanacaoForm",
          },
        ],
      },
      {
        path: "demandas",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "formulario",
            component: () => import("@/views/Demandas/Criar.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "solicitacao",
            component: () => import("@/views/Demandas/Solicitacao.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "solicitacoes",
            component: () => import("@/views/Demandas/ListarSolicitacoes.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Demandas/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Demandas/Show.vue"),
          },
          {
            path: "/",
            component: () => import("@/views/Demandas/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "processos",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "formulario",
            component: () => import("@/views/Processos/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id/formulario",
            component: () => import("@/views/Processos/Form.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Processos/Show.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "/",
            component: () => import("@/views/Processos/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "chat",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "/",
            component: () => import("@/views/Chats/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "new-chat",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "/",
            component: () => import("@/views/Chat/Index.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
      {
        path: "permissoes",
        component: () => import("@/views/Permissoes/ListaPermissoes.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "admin",
        component: () => import("@/views/Admin/Admin.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "perfil",
        component: () => import("@/views/Users/Profile.vue"),
        beforeEnter: checkAuthentication,
      },
      {
        path: "fluxos",
        component: () => import("@/components/EmptyParentComponent/Index.vue"),
        beforeEnter: checkAuthentication,
        children: [
          {
            path: "/",
            component: () => import("@/views/Fluxo/Index.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: "formulario",
            component: () => import("@/views/Fluxo/Create.vue"),
            beforeEnter: checkAuthentication,
          },
          {
            path: ":id",
            component: () => import("@/views/Fluxo/Show.vue"),
            beforeEnter: checkAuthentication,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
