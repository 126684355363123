<template>
  <v-app-bar
    app
    dark
    color="primary"
    v-if="isAuthenticated"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  >
    <v-app-bar-nav-icon @click.stop="handleChangeDrawer()">
      <v-btn icon>
        <v-icon>fas fa-bars</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>

    <v-spacer></v-spacer>
    <h3 v-if="permissions && permissions.cliente">
      {{ permissions.cliente.nome_cliente }}
    </h3>
    <v-spacer></v-spacer>
    <v-btn
      v-if="me && me.role && me.role.name != 'educa_ws'"
      to="/app/new-chat"
      icon
    >
      <v-badge
        :color="getCount > 0 ? 'red' : 'transparent'"
        overlap
        :content="getCount"
        class="mr-2"
      >
        <v-icon> fas fa-comments </v-icon>
      </v-badge>
    </v-btn>
    <v-btn
      v-if="me && me.role && me.role.name != 'educa_ws'"
      @click="emit()"
      icon
    >
      <v-badge
        :color="count > 0 ? 'red' : 'transparent'"
        overlap
        :content="count"
        class="mr-2"
      >
        <v-icon> fas fa-bell </v-icon>
      </v-badge>
    </v-btn>
    <UserMenu />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";

export default {
  name: "SystemAppBar",

  components: {
    UserMenu: () => import("../../components/User/ProfilleMenu/Index"),
  },

  computed: {
    ...mapGetters({
      drawer: "getSystemBarAndNavigation",
      isAuthenticated: "getIsAuthenticated",
      permissions: "getPermissions",
      me: "getMe",
    }),

    getCount() {
      let count = 0;

      this.chats.forEach((chat) => (count = count + chat.notifications));

      return count;
    },
  },

  data() {
    return {
      count: 0,
      countMessages: 0,
      chats: [],
    };
  },

  mounted() {
    this.$root.$on("updateNotify", () => this.getNotifys());
    this.getNotifys();
    this.getChats();
  },

  methods: {
    async getNotifys() {
      const { data } = await instanceAxios.get("notifications");

      this.count = data.length;
    },
    handleChangeDrawer() {
      this.$store.dispatch("systemBarAndNavigation", !this.drawer);
    },
    emit() {
      this.$root.$emit("openNotifications");
    },

    async getMessages(id) {
      try {
        window.db
          .collection("chat")
          .where("chat_id", "==", id)
          .orderBy("created_at")
          .onSnapshot((query) => {
            let allMessages = [];
            let count = 0;

            let me = this.me;

            query.forEach((obj) => {
              allMessages.push({ id: obj.id, ...obj.data() });

              let data = obj.data();

              const newMessage = data.views.find((userId) => userId == me.id);

              if (!newMessage) {
                count = count + 1;
              }
            });

            let index = this.chats.findIndex((el) => el.id === id);

            this.chats[index].message = allMessages[allMessages.length - 1];
            this.chats[index].notifications = count;
          });
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    async getChats() {
      window.db
        .collection("chats")
        .orderBy("updated_at", "desc")
        .onSnapshot((query) => {
          let allChats = [];
          query.forEach((obj) => {
            let exist = obj.data().users.find((el) => el.id == this.me.id);

            if (exist) {
              allChats.push({
                id: obj.id,
                ...obj.data(),
                message: { user: {} },
                notifications: 0,
              });

              this.getMessages(obj.id);
            }
          });

          this.chats = allChats;
        });
    },
  },
};
</script>
