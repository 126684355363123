import store from "@/store/index.js";

export default (role) => {
  const me = store.getters["getMe"];
  const dataPermissions = store.getters["getPermissions"];

  if (role == null) {
    return true;
  }

  if (me.role.type == "responsible") {
    return true;
  }

  if (role == "none") {
    if (me.role.type == "responsible") {
      return true;
    } else {
      return false;
    }
  }

  console.log(me);
  console.log(dataPermissions);

  const permissions = JSON.parse(dataPermissions.permissions);

  if (permissions !== undefined) {
    if (permissions[role]) {
      return true;
    }
    return false;
  }

  return true;
};
