import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "./vuexPersist";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [VuexPersistence],
  state: {
    me: {},
    toast: {},
    permissions: [],
    accessToken: "",
    modalChangePassword: false,
    systemBarAndNavigation: true,
  },
  getters: {
    getSolicitacao: (state) => state.solicitacao,
    getMe: (state) => state.me,
    getToast: (state) => state.toast,
    getPermissions: (state) => state.permissions,
    getAccessToken: (state) => state.accessToken,
    getIsAuthenticated: (state) => !!state.accessToken,
    getModalChangePassword: (state) => state.modalChangePassword,
    getSystemBarAndNavigation: (state) => state.systemBarAndNavigation,
  },
  actions: {
    solicitacao({ commit }, data) {
      commit('setSolicitacao', data)
    },

    me({ commit }, data) {
      commit("setMe", data);
    },

    permissions({ commit }, data) {
      commit("setPermissions", data);
    },

    toast({ commit }, data) {
      commit("setToast", data);
    },

    login({ commit }, data) {
      console.log('data ', data)
      commit("setAccessToken", `${data.tokenType} ${data.accessToken}`);
    },

    logout({ commit }) {
      commit("setMe", {});
      commit("setPermissions", []);
      commit("setAccessToken", "");
    },

    modalChangePassword({ commit }, status) {
      commit("setModalChangePassword", status);
    },

    systemBarAndNavigation({ commit }, status) {
      commit("setSystemBarAndNavigation", status);
    },
  },

  mutations: {
    setSolicitacao(state, data) {
      state.solicitacao = data;
    },
    setMe(state, me) {
      state.me = me;
    },

    setToast(state, data) {
      state.toast = data;
    },

    setPermissions(state, permissions) {
      state.permissions = permissions;
    },

    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },

    setModalChangePassword(state, status) {
      state.modalChangePassword = status;
    },

    setSystemBarAndNavigation(state, status) {
      state.systemBarAndNavigation = status;
    },
  },

  modules: {},
});
